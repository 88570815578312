import React from "react";

function Services (props) {
    return (
       <div>
        <h4 className='ServicesNames'>{props.title}</h4>
       </div> 
    )
}

export default Services