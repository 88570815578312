import React, { useRef } from 'react';
import { Drawer } from 'antd';
import './Popup.css'
import { Input } from "@material-ui/core";
import emailjs from '@emailjs/browser';


const Popup = (props) => {

  const form = useRef()
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9w526d8', 'template_39e65tm', form.current, 'xKKW1WbLgfT4t4gu-')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }


  return (
    <>
      <Drawer className='contact-drawer' placement="right" onClose={props.onClose} open={props.open}>
        <div>
          <form onSubmit={sendEmail} ref={form} className='Text'>
           
        
          <p className='TextSec'>Hi, My Name/My Company Name is</p>
          <Input placeholder="Furkan" name='from_name' />
          <p className='Text'>. You can answer me on this e-mail</p>
          <Input placeholder="furkan_ayag@outlook.com" name='from_email'  />
          <p className='Text'>.
          I/My company need tech service on </p>
          <Input placeholder="website etc." name='from_service' />
          <p className='Text'>.Budget is $ </p>
          <Input placeholder="2000" name='from_budget' />
          <p className='Text'> and it needs to be done by </p>
          <Input placeholder="Nov, 2023" name='from_time' />.

          <div><button className='submit-button' >Submit</button></div>
          </form>
        </div>
      </Drawer>
    </>
  );
};
export default Popup;